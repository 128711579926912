@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
body {
  background-color: black;
}

@layer components {
  .my-custom-style {
    /* ... */
  }

  .btn-primary {
    @apply inline-block w-full py-[12px]   text-base font-bold  text-[#12221A] capitalize transition duration-150 ease-in-out bg-[#A5F33C] disabled:bg-[#557C1E] disabled:border-[#557C1E] disabled:text-[#213E28] disabled:hover:before:hidden rounded-full shadow-md px-7 hover:before:absolute hover:before:w-[80%] hover:before:h-[16px] hover:before:bottom-0 hover:before:bg-[#A5F33C] hover:before:z-[-1] hover:z-10 hover:before:left-[50%] hover:before:-translate-x-[50%] hover:relative hover:before:blur-[19px]  hover:shadow-lg focus:bg-[#A5F33C] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#A5F33C] active:shadow-lg;
  }
  .btn-primary-after {
    @apply disabled:hover:before:hidden hover:before:absolute hover:before:w-[80%] hover:before:h-[16px] hover:before:bottom-0 hover:before:bg-[#A5F33C] hover:before:z-[-1] hover:z-10 hover:before:left-[50%] hover:before:-translate-x-[50%] hover:relative hover:before:blur-[19px];
  }

  .btn-primary-2 {
    @apply px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
  .btn-secondary {
    @apply inline-block w-full capitalize py-[11px] text-base font-bold   disabled:text-[#213E28] disabled:hover:before:hidden disabled:hover:bg-[#060C07] disabled:cursor-not-allowed disabled:border-[#557C1E] text-white  transition duration-150 ease-in-out  rounded-full shadow-md px-7 border-[1px] border-[#A5F33C] hover:bg-[#A5F33C] hover:border-[#A5F33C] hover:text-[#12221A] hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-lime-900 active:shadow-lg   hover:z-10  hover:relative;
  }
  .menu-selected-before {
    @apply before:top-0 before:left-0 before:absolute before:h-full before:w-1 before:bg-[#A5F33C] before:rounded-r;
  }
  .menu-selected-after {
    @apply after:top-0 after:left-0 after:absolute after:h-full after:w-1 after:bg-[#A5F33C] after:rounded-r after:opacity-[0.5] after:blur-sm after:rounded-r;
  }
  .background-border-rad-before {
    @apply before:top-0 before:left-0 before:absolute before:h-full before:w-[32px] before:bg-red/20 before:rounded-r;
  }

  .my-container {
    @apply xl:container md:max-w-[1150px] px-4 lg:px-16 m-auto  z-[1px] relative min-h-[calc(100vh-314px)];
  }
  .drop-menus-list {
    @apply bg-[#060C07] flex flex-col min-w-[115px] py-4 px-0  z-20 border rounded-lg border-[#A4B1A7]/25;
  }
  .drop-menu-link {
    @apply inline-block  font-normal py-1  px-4  text-base text-white  focus-visible:bg-[#12221A]/80 font-manrope active:bg-[#12221A]/80 active:text-white hover:bg-[#12221A]/80 hover:text-[#FFFFFF] outline-0;
  }

  .before-key-value-icon-success {
    @apply before:absolute before:w-2 before:h-2 before:bg-[#B0E577] before:-left-4 before:top-2 before:rounded-full;
  }
  .before-key-value-icon-error {
    @apply before:absolute before:w-2 before:h-2 before:bg-[#F87171] before:-left-4 before:top-2 before:rounded-full;
  }
  .before-key-value-icon-other {
    @apply before:absolute before:w-2 before:h-2 before:bg-[#DC2626] before:-left-4 before:top-2 before:rounded-full;
  }
  .custom-tooltip-style {
    @apply text-[white] text-xs bg-[#0A130D]  p-3  border border-[#A4B1A7]/[0.12] rounded-lg;
  }
 
  /* .imageContainer {
    @apply w-56 h-56;
    perspective: 1500px;
    @apply mx-auto;
    
  }
  .image-container img {
    @apply rounded-full;
    transform: rotate(0);
    transition: .3s;
  }
  .image-container:hover img {
    transform: rotateX(15deg) rotateY(-18deg) rotateZ(3deg);
  } */
}

@layer utilities {
  .background-linear-grad {
    background: linear-gradient(
      312.91deg,
      rgba(89, 194, 123, 0.09) 2.76%,
      rgba(247, 198, 68, 0.09) 40.09%,
      rgba(236, 86, 118, 0.09) 67.41%,
      rgba(20, 79, 245, 0.09) 101.67%
    );
  }
  .background-linear-gradient-border {
    background: linear-gradient(
      330deg,
      rgba(89, 194, 123, 0.7),
      rgba(247, 198, 68, 0.7),
      rgba(236, 86, 118, 0.7),
      rgba(20, 79, 245, 0.7)
    );
  }
  .background-linear-gradient-border-full {
    background: linear-gradient(
      330deg,
      rgba(89, 194, 123, 0.9),
      rgba(247, 198, 68, 0.9),
      rgba(236, 86, 118, 0.9),
      rgba(20, 79, 245, 0.9)
    );
  }
  .font-poppin {
    font-family: "Poppins", "sans-serif";
  }
  .background-border-rad {
    /* background: linear-gradient(93.36deg, rgba(255, 255, 255, 0.62) 6.61%, rgba(255, 255, 255, 0) 120.24%); */
    background: rgba(164, 177, 167, 0.12);
  }
  .background-green {
    background: linear-gradient(
      180deg,
      rgba(33, 62, 40, 0.44) 0%,
      rgba(33, 62, 40, 0.21) 100%
    );
  }

  .step-line-after.active-step::after {
    content: "";
    position: absolute;
    top: 32px;
    left: 0;
    margin-left: 16px;
    width: 1.5px;
    height: 40px;
    background: #a5f33c;
  }

  .step-line-after::after {
    content: "";
    position: absolute;
    top: 32px;
    left: 0;
    margin-left: 16px;
    width: 1px;
    height: 43px;
    background-color: #4d6553;
  }

  .step-line-after:last-child::after {
    display: none;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    /* border: 2px solid rgb(43, 43, 43); */
    -webkit-text-fill-color: white;
    /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
    transition: background-color 5000s ease-in-out 0s;
    caret-color: white;
    background: #12221a;
    /* font-size: 16px; */
  }
}

.d-inherit {
  display: inherit;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 16px;
  height: 4px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  /* background: #E4EBF6;
   */
  background: #a5f33c;

  /* border-radius: 5rem; */
  background-clip: content-box;
  border-left: 14px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  /* background: #E4EBF6; */
  background-clip: content-box;
  border-left: 14px solid transparent;
}

::-webkit-scrollbar-thumb:active {
  /* background: #E4EBF6; */
  background: #a5f33c;
}

::-webkit-scrollbar-track {
  /* background: rgba(148, 163, 184, 0.1); */
  border: 0px none transparent;

  margin-bottom: 30px;
  margin-top: 24px;
  border-left: 12px solid transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white;
  color: white;
}

.color-selector {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid red;
  margin: 0 auto;
  min-width: 100%;
  border-radius: 4px;
  position: relative;
  padding: 12px 16px;
  background: white;
}
.circle {
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin-right: 10px;
}

.hidden {
  display: none;
  left: 0;
  opacity: 0;
  width: 0;
  visibility: hidden;
  padding: 0;
  position: absolute;
}

span {
  display: inline-block;
  vertical-align: middle;
}

.colors-style {
  border-radius: 4px;
  padding: 12px 16px;
}
.circle {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
/* calender design */
.rdrStaticRangeLabel {
  color: #ffffff;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px !important;
  padding: 0px !important;
  background: transparent !important;
}
.rdrMonth {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 24px !important;
  padding-top: 12px !important;
}
.rdrWeekDays {
  margin-top: 16px !important;
  margin-bottom: 12px !important;
}
/* .rdrDayEndOfWeek .rdrStartEdg{
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.rdrDayEndPreview{
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
} */

.rdrInRange {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.rdrDateDisplayItem input {
  text-align: start !important;
  width: 88% !important;
  border-bottom: 1px solid white !important;
}
.rdrMonthAndYearPickers {
  display: none !important;
}
.rdrNextPrevButton {
  position: absolute;
  right: 3%;
}
.rdrDateDisplayItem input:focus {
  color: white !important;
}
.rdrDateDisplayItemActive {
  border-bottom: none;
}
.rdrCalendarWrapper {
  font-size: 14px !important;
  background-color: #060c07 !important ;
  border-top-right-radius: 12px !important;
  border-radius: 16px !important;
}
.rdrMonthAndYearPickers {
  color: white !important;
}
.rdrMonthPicker select {
  color: white !important;
}
.rdrYearPicker select {
  color: white !important;
}

/* Column chart */
.apexcharts-menu-icon {
  display: none;
}
.apexcharts-toolbar {
  display: none !important;
}
.apexcharts-svg {
  background: none !important;
}
.rdrDayNumber span {
  color: white !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.rdrStartEdge {
  background-color: #a5f33c !important;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.rdrEndEdge {
  background-color: #a5f33c !important;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.rdrDayStartPreview {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.rdrDayDisabled {
  background: transparent !important;
}
.rdrDayDisabled .rdrDayNumber span {
  color: rgba(255, 255, 255, 0.3) !important;
}
.rdrDateDisplayWrapper {
  background-color: #12221a !important;
}
.rdrDateInput {
  border-bottom: 1px solid white !important;
  background-color: transparent !important;
}
/* .rdrDateDisplayItemActive{
  border-bottom:1px solid white !important;
  border-color: 1px solid red !important;
} */
.rdrDateDisplayItem {
  border: none !important;
}
.rdrStaticRange:hover .rdrStaticRangeLabel {
  background: rgba(18, 34, 26, 0.3) !important;
  color: #a5f33c !important;
}
.rdrDefinedRangesWrapper .rdrStaticRangeSelected span {
  color: #a5f33c !important;
}
.rdrDefinedRangesWrapper .rdrStaticRange .rdrStaticRangeLabel span {
  color: blue !important;
}
.rdrDay {
  padding: 18px !important;
  /* max-height: 24px !important; */
  width: fit-content !important;
}

.rdrWeekDay {
  font-size: 10px !important;
  width: 36px !important;
  flex-basis: auto !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 15px !important;
  color: var(--shades-white, #fff) !important;
  text-transform: uppercase !important;
}
.rdrStaticRange {
  background: transparent !important;
  border-bottom: 0px solid transparent !important;
  padding-bottom: 16px !important;
}
.rdrStaticRanges {
  position: absolute !important;
  top: 54px !important;
  padding-left: 24px !important;
}
.rdrNextButton i {
  border-width: 0px !important;
  background-image: url(/src/assets/images/icons/rightArrowCalender.svg) !important;
  width: 16px !important;
  height: 17px !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.rdrPprevButton i {
  border-width: 0px !important;
  background-image: url(/src/assets/images/icons/leftArrowCalender.svg) !important;
  width: 16px !important;
  height: 17px !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.rdrPprevButton {
  position: absolute;
  right: 10%;
}
.rdrNextPrevButton {
  background: none !important;
}
/* .rdrMonthPicker, .rdrYearPicker{
  display: none !important;
} */
.rdrMonthName {
  position: absolute;
  top: 24px;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  color: #fff !important;
  padding: 0px !important;
  padding-left: 2px !important;
}

.rdrDay .rdrStartEdge + .rdrDayNumber span {
  color: #060c07 !important;
}
.rdrDay .rdrEndEdge + .rdrDayNumber span {
  color: #060c07 !important;
}
.rdrDay .rdrStartEdge + span + .rdrDayNumber span {
  color: #060c07 !important;
}
.rdrDay .rdrEndEdge + span + .rdrDayNumber span {
  color: #060c07 !important;
}
.rdrMonthAndYearWrapper {
  margin-bottom: 16px !important;
  padding-top: 0px !important;
}
.rdrInputRanges {
  padding: 0px !important;
}
.rdrMonthName {
  display: none !important;
}

.rdrMonthAndYearPickers select {
  padding: 0px !important;
  text-align: start !important;
}
.rdrMonthAndYearPickers {
  flex: 0 !important;
  padding-left: 20px !important;
}
.rdrYearPicker {
  margin: 0px !important;
}
.rdrMonth {
  width: 308px !important;
}
.rdrDefinedRangesWrapper {
  background-color: rgba(18, 34, 26, 0.3) !important;
  width: 114px !important;
  border-right: none !important;
  border-top-left-radius: 6px;
}
.rdrDayPassive span span {
  color: rgba(255, 255, 255, 0.3) !important;
}
.rdrDateRangePickerWrapper {
  position: relative !important;
}
/* placeholder date display */
.rdrDateDisplay {
  display: none !important;
}
.rdrDayInPreview {
  background: currentColor !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.rdrDateDisplayItem {
  flex: initial !important;
  width: auto !important;
  margin: 0px 1px !important;
}
.rdrDayStartPreview {
  border: 0px solid #000 !important;
  opacity: 0.9 !important;
  background: transparent !important;
  background-color: transparent !important;
}
.custom-gradient {
  background: radial-gradient(
    184.01% 107.66% at 17.79% 25.35%,
    rgba(0, 0, 0, 0.37) 0%,
    rgba(0, 0, 0, 0.69) 100%
  );
  backdrop-filter: blur(14px);
}
.dot-flashing {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #a5f33c;
  color: #a5f33c;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -24px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #a5f33c;
  color: #a5f33c;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 24px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #a5f33c;
  color: #a5f33c;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #a5f33c;
  }
  50%,
  100% {
    background-color: rgba(165, 243, 60, 0.2);
  }
}

/* Date Picker Styling */
.react-datepicker {
  background: #060c07 !important;
  border: 1px solid rgba(164, 177, 167, 0.12) !important;
}
.react-datepicker__time-container {
  border-left: 1px solid rgba(164, 177, 167, 0.12) !important ;
}
.react-datepicker__day {
  color: white !important;
}
.react-datepicker__day:hover {
  background: rgba(165, 243, 60, 0.09) !important ;
}
.react-datepicker__day--selected {
  background: #a5f33c !important ;
  color: #060c07 !important;
  font-weight: bold !important;
}
.react-datepicker__day--selected:hover {
  background: #a5f33c !important;
}
.react-datepicker__time-list {
  background: #060c07;
}
.react-datepicker__time-list-item {
  color: white;
}
.react-datepicker__time-list-item:hover {
  background: rgba(165, 243, 60, 0.09) !important ;
}
.react-datepicker__header {
  background-color: rgba(18, 34, 26, 0.3) !important ;
  border-bottom: 1px solid rgba(164, 177, 167, 0.12) !important;
}
.react-datepicker-time__header {
  color: white !important;
  border-bottom: 1px solid transparent !important;
}
.react-datepicker__current-month {
  color: white !important;
}
.react-datepicker__month-read-view--selected-month {
  color: white !important;
}
.react-datepicker__year-read-view--selected-year {
  color: white !important;
}
.react-datepicker__day-name {
  color: white !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  display: none !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  display: none !important;
}
.react-datepicker__month-dropdown {
  background-color: #060c07 !important;
  color: white !important;
  border: 1px solid rgba(164, 177, 167, 0.12) !important;
}
.react-datepicker__month-option:hover {
  background: rgba(165, 243, 60, 0.09) !important;
}
.react-datepicker__year-dropdown {
  background-color: #060c07 !important;
  color: white !important;
  border: 1px solid rgba(164, 177, 167, 0.12) !important;
}
.react-datepicker__year-option:hover {
  background: rgba(165, 243, 60, 0.09) !important;
  color: white !important;
}
.react-datepicker__day--keyboard-selected {
  background: #a5f33c !important;
  color: #060c07 !important;
}
.react-datepicker__day--keyboard-selected:hover {
  background: #a5f33c !important;
}
.react-datepicker__day--today:focus-within {
  outline-width: 0px !important;
}
.react-datepicker__time-list-item--selected {
  background: #a5f33c !important;
  color: #060c07 !important;
  border-radius: 4px !important;
}
.react-datepicker__time-list-item--selected:hover {
  background: #a5f33c !important;
}
.react-datepicker__navigation-icon::before {
  border-width: 1px 1px 0 0 !important;
  border-color: white !important;
}
.react-datepicker__month-read-view--down-arrow {
  border-width: 1px 1px 0 0 !important;
  border-color: white !important;
}
.react-datepicker__year-read-view--down-arrow {
  border-width: 1px 1px 0 0 !important;
  border-color: white !important;
}

.nonTransferIcon:hover svg rect {
  fill: #060C07;
  transition: fill 0.3s
}

.nonTransferIcon:hover svg path {
  stroke: white;
  transition: stroke 0.3s
}


.btnContainer{
  width:8em;
  margin-left: 5%;
}
.ButtonLeft{
float:right;
}
.ButtonLeft {
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 1.5em;
  color: #FFFFFF;
  transition: all 0.5s;
}
.ButtonLeft span:before{
  content: '\00ab';
  position: relative;
  opacity: 0;
  left: -0.05em;
  transition: 0.5s;
}
.ButtonLeft:hover{
  background: white;
  padding-left: 4px;
}
.ButtonLeft span{
  white-space:nowrap;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.ButtonLeft:hover span{
  padding-left: 8px;
  left: -4px;
}
.ButtonLeft:hover span:before{
  left: -1.5em;
  opacity: 1;
  color: inherit;
}
.ButtonRight span{
  white-space:nowrap;
  display: inline-block;
  position: relative;
  right: -0.05em;
  transition: 2s;
}
.trasfer {
  position: absolute;
 
 right: 24px;
 top:24px
}
.trasfer:hover {
  right: 100px;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #FFF;
  overflow: hidden;
  width: 0px;
  height: 24px;
  transition: .5s ease;
  border-radius: 24px;
}


.text {
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.texts {
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 5%;
  transform: translate(-5%, -50%);
  -ms-transform: translate(-5%, -50%);}